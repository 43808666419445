<div class="content">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h2 *ngIf="user.role === 'ROLE_AGENCY'">Submit candidate</h2>
        <h2 *ngIf="user.role !== 'ROLE_AGENCY'">New recommendation</h2>
      </div>
    </div>

    <form [formGroup]="form" (ngSubmit)="submit()">
      <div class="row">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-6 name-margin">
              <label class="control-label" for="candidate-first-name">First name <span class="color-red">*</span></label>
              <input placeholder="First name of candidate" type="text" id="candidate-first-name" class="form-control" formControlName="candidateFirstName">
              <div *ngIf="form.get('candidateFirstName').invalid && form.get('candidateFirstName').touched" class="validation">
                <small *ngIf="form.get('candidateFirstName').errors['required']  || form.get('candidateFirstName').errors['pattern']">First name cant be empty</small>
                <small *ngIf="form.get('candidateFirstName').errors['forbiddenStrings']">Invalid field value</small>
              </div>
            </div>
            <div class="col-md-6">
              <label class="control-label" for="candidate-last-name">Last name <span class="color-red">*</span></label>
              <input placeholder="Last name of candidate" type="text" id="candidate-last-name" class="form-control" formControlName="candidateLastName">
              <div *ngIf="form.get('candidateLastName').invalid && form.get('candidateLastName').touched" class="validation">
                <small *ngIf="form.get('candidateLastName').errors['required'] || form.get('candidateLastName').errors['pattern']">Last name cant be empty</small>
                <small *ngIf="form.get('candidateLastName').errors['forbiddenStrings']">Invalid field value</small>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label class="control-label" for="candidate-email">Email <span class="color-red">*</span>&nbsp;<span *ngIf="user.role === 'ROLE_AGENCY'" class="alert" [ngbTooltip]="'It is mandatory to provide the candidate\'s email (not the agency\'s)'" [placement]="'right'">!</span></label>
            <input placeholder="candidate@mail.com" type="text" id="candidate-email" class="form-control" formControlName="candidateEmail">
            <div *ngIf="form.get('candidateEmail').invalid && form.get('candidateEmail').touched" class="validation">
              <small *ngIf="form.get('candidateEmail').errors['required']">Email cant be empty</small>
              <small *ngIf="form.get('candidateEmail').errors['email']">Write Correct Email</small>
              <small *ngIf="form.get('candidateEmail').errors['pattern']">DXC employees are not accepted</small>
            </div>
          </div>
          <div class="form-group">
            <label class="name control-label" for="candidate-phone">Phone</label>
            <input placeholder="+..." type="text" id="candidate-phone" class="form-control" formControlName="candidatePhone">
            <div *ngIf="form.get('candidatePhone').invalid && form.get('candidatePhone').touched" class="validation">
              <small *ngIf="form.get('candidatePhone').errors['pattern']">Please enter a valid phone number</small>
            </div>
          </div>
          <div class="form-group">
            <label for="candidate-country" class="control-label">Candidate’s location (Country) <span class="color-red">*</span></label>
            <ng-select [items]="countryList"
                       [clearable]="true"
                       bindLabel="name"
                       bindValue="code"
                       placeholder="Select country"
                       labelForId="candidate-country"
                       formControlName="candidateCountryCode">
            </ng-select>
            <div *ngIf="form.get('candidateCountryCode').invalid && form.get('candidateCountryCode').touched" class="validation">
              <small *ngIf="form.get('candidateCountryCode').errors['required']">Country cant be empty</small>
            </div>
          </div>
          <div class="form-group" *ngIf="user.role !== 'ROLE_AGENCY'">
            <label class="name control-label" for="recruiter-name">Recruiter email</label>
            <input placeholder="Enter recruiter's @dxc email if known" type="text" id="recruiter-name" class="form-control" formControlName="recruiterName">
            <div *ngIf="form.get('recruiterName').invalid && form.get('recruiterName').touched" class="validation">
              <small *ngIf="form.get('recruiterName').errors['email']">Write Correct Email</small>
            </div>
          </div>
          <div class="form-group">
            <label for="candidate-file-name" class="control-label">CV / Resume&nbsp;<span *ngIf="user.role === 'ROLE_AGENCY'" class="color-red" > * </span>
              <span *ngIf="user.role === 'ROLE_AGENCY'" class="alert" [ngbTooltip]="'Ensure that the CV doesn\'t contain agency\'s email and phone number'" [placement]="'right'">!</span>
            </label>
            <div class="input-group width-100">
              <input placeholder="Choose file, max size 10 MB" formControlName="cvFile" class="form-control form-rect" id="candidate-file-name" readonly="">
              <span class="input-group-btn">
                <span class="btn btn-default btn-file btn-rect">
                    Browse
                  <input type="file" id="resumeFile" name="resumeFile" (change)="handleFileInput($event.target)">
                </span>
            </span>
            </div>
            <div *ngIf="form.get('cvFile').invalid && form.get('cvFile').touched" class="validation">
              <small *ngIf="form.get('cvFile').errors['required']">CV is required</small>
            </div>
          </div>
          <div *ngIf="user.role === 'ROLE_AGENCY'" style="line-height: 1px; color: red">Ensure that the CV doesn't contain agency's email and phone number</div>
          <div class="form-group">
            <label class="name control-label" for="comment">Comment</label>
            <textarea rows="2" placeholder="Details about candidate motivation, notice period, visa, etc." type="text" id="comment" class="form-control" formControlName="comment">
              </textarea>
            <div *ngIf="form.get('comment').invalid && form.get('comment').touched" class="validation">
              <small *ngIf="form.get('comment').errors['forbiddenStrings']">Invalid field value</small>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="col-md-12" *ngIf="user.role !== 'ROLE_AGENCY' && (!vacancies || vacancies.length < 1)">
            <label for="candidate-specialization" class="control-label">Specialization <span
              class="color-red">*</span></label>
            <ng-select [items]="specializationList"
                       [clearable]="true"
                       bindLabel="name"
                       bindValue="name"
                       placeholder="Select specialization"
                       labelForId="candidate-specialization"
                       formControlName="specialization">
            </ng-select>
            <div *ngIf="form.get('specialization').invalid && form.get('specialization').touched" class="validation">
              <small *ngIf="form.get('specialization').errors['required']">Specialization cant be empty</small>
            </div>
            <div class="form-group">
              <label class="name control-label" for="vacancy-name">Vacancy</label>
              <input placeholder="Enter the VR-key of the vacancy, if known" type="text" id="vacancy-name" class="form-control"
                     formControlName="vacancyCode" [readOnly]="!!careerVacancy.vrKey">
              <div *ngIf="form.get('vacancyCode').invalid && form.get('vacancyCode').touched" class="validation">
                <small *ngIf="form.get('vacancyCode').errors['forbiddenStrings']">Invalid field value</small>
              </div>
            </div>
          </div>
          <ul class="links">
            <li *ngFor="let vacancy of vacancies; let i = index">
              <div class="row">
                <div class="col-md-11 width-90">
                  <div class="col-md-12">
                    <span class="title"><strong>{{vacancy.vrName}}</strong></span>
                  </div>
                  <div class="col-md-12 font-size-14">
                    <span class="spec-name">{{vacancy.specializationName}}</span>
                    <span><mat-icon class="white-icon p-t-2">location_on</mat-icon><span *ngIf="vacancy.workingPlaceCity">{{vacancy.workingPlaceCity}}, </span>{{vacancy.countryName}}</span>
                    <span class="key-name">&#8226;</span>
                    <span>{{vacancy.vrKey}}</span>
                  </div>
                </div>
                <div *ngIf="user.role === 'ROLE_AGENCY'" class="col-md-1 vac-name">
                    <span class="pointer" (click)="removeVacancy(vacancy)">
                      <mat-icon class="color-gray">delete</mat-icon>
                    </span>
                </div>
              </div>

            </li>
          </ul>
        </div>

      </div>
      <div class="row">
        <div class="row description" *ngIf="user.role !== 'ROLE_AGENCY'">
          <div>
            <p><a href="https://www.luxoft.com/global-presence" class="text-black underline">Luxoft Holding Inc</a>.
              is the primary data controller of the personal data you provide about you and your candidate.
              The <strong class="text-black">purpose</strong> of this processing is to contact your candidate and initiate an application process for an employment,
              internship or relocation with Luxoft Holding Inc., a relevant affiliate of the <a class="text-black underline" href="https://www.luxoft.com/global-presence">Luxoft Group</a> or Luxoft Group’s clients,
              as applicable, and to award you, the reference provider, with a bonus for (an) eligible recommendation(s).</p>
          </div>
          <div>
            <p>To the extent necessary for this purpose and as applicable to your individual request, Luxoft Holding Inc. can
              share your data with relevant affiliates of the Luxoft Group or Luxoft Group’s clients in accordance with this
              <a class="text-black underline" href="https://www.luxoft.com/online-privacy-policy/#disclosing-and-transfer">Privacy Notice</a>. These third parties can be additional data controllers or data processors as applicable.</p>
          </div>
          <div>
            <p><strong class="text-black"><span class="underline">Before you submit this form,</span> please refer your candidate to our </strong><a class="anchor" href="https://www.luxoft.com/online-privacy-policy/#disclosing-and-transfer">Privacy Notice</a> <strong class="text-black"> and do not submit this information
              without <span class="underline">your friend’s consent that we receive and process their data in order to contact them.</span></strong> You acknowledge that
              as soon as you submit the recommendation, we will start processing your candidate’s personal data and the referred candidate
              will receive an automatic notification in which we inform the candidate that you provided us their personal data.</p>
          </div>
          <div>
            <p>By clicking the <strong class="text-black">Submit</strong> button you will conclude a contract with Luxoft Holding Inc. about your participation
              in this referral program. The necessity to fulfill this contract is the legal basis based for Luxoft Holding
              Inc.’s processing of your personal data for the abovementioned purpose. We will seek for your candidate’s separate consent.</p>
          </div>
          <div class="form-group checkbox-wrapper">
            <div class="checkbox">
              <label for="terms" class="required">
                <input type="checkbox" id="terms" class="checkbox-style" name="terms" formControlName="agreeTerms">
                <span>I have read and agree with the terms and conditions described in the Referral Program Rules.</span>
                <div *ngIf="form.get('agreeTerms').invalid && form.get('agreeTerms').touched" class="validation">
                  <small *ngIf="form.get('agreeTerms').errors['required']">You must be agree</small>
                </div>
              </label>
            </div>
          </div>
          <div>
            <p>Eligibility criteria and detailed for internal reference providers rules can be found in the
              <a class="text-black underline" href="https://luxproject.luxoft.com/confluence/pages/releaseview.action?pageId=59803316">Referral Program Rules</a>
              and for the external reference providers: <a class="text-black underline" href="https://career.luxoft.com/referral-program/">here</a>. For externals, the referral program is currently only available in Mexico, Poland, Romania, Serbia and Ukraine.</p>
          </div>
          <div>
            <p>More information, including about my data subject rights: <a class="text-black underline" href="https://www.luxoft.com/online-privacy-policy/#disclosing-and-transfer">Privacy Notice</a>.</p>
          </div>
        </div>
        <div class="row description" *ngIf="user.role === 'ROLE_AGENCY'">
          <div>
            <p><a href="https://www.luxoft.com/global-presence" class="text-black underline">Luxoft Holding Inc</a>.
              is the primary data controller of the personal data you provide about yourself (if any) and your candidate.
              The <strong class="text-black">purpose</strong> of this processing is to contact your candidate and initiate an application process for an employment,
              internship or relocation with Luxoft Holding Inc., a relevant affiliate of the <a class="text-black underline" href="https://www.luxoft.com/global-presence">Luxoft Group</a> or Luxoft Group’s
              clients, as applicable, and to award you, the recruiter, with a bonus for (an) eligible recommendation(s) in accordance with our individual agreement with you.</p>
          </div>
          <div>
            <p>To the extent necessary for this purpose and as applicable to your individual request, Luxoft Holding Inc.
              can share your data with relevant affiliates of the Luxoft Group or Luxoft Group’s clients in accordance with
              this <a class="text-black underline" href="https://www.luxoft.com/online-privacy-policy/#disclosing-and-transfer">Privacy Notice</a>. These third parties can be additional data controllers or data processors as applicable.</p>
          </div>
          <div>
            <p><strong class="text-black"><span class="underline">Before you submit your candidate’s personal data,</span> please refer your candidate to our
              <a class="underline color-purple" href="https://www.luxoft.com/online-privacy-policy/#disclosing-and-transfer">Privacy Notice</a>.
              You must not submit this information if you do not have a legal basis to share this data with us
              (e.g. your candidate’s consent or a contract with your candidate).</strong> You acknowledge that as soon as you
              submit the recommendation, we will start processing your candidate’s personal data and the referred candidate
              will receive an automatic notification in which we inform the candidate that you provided us their personal data.</p>
          </div>
          <div>
            <p>Eligibility criteria for your entitlement to a bonus are subject to our individual agreement with you.
              The necessity to fulfill said contract is the legal basis for Luxoft Holding Inc.’s processing of your
              personal data for the abovementioned purpose. We will seek for your candidate’s separate consent, as well
              as inform the candidate about our processing activities in accordance with the legal requirements.</p>
          </div>
          <div>
            <p>More information, including about your data subject rights: <a class="text-black underline" href="https://www.luxoft.com/online-privacy-policy/#disclosing-and-transfer">Privacy Notice</a>.</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="button" >
            <button [disabled]="inProgress" class="btn custom-btn" type="submit"><span *ngIf="inProgress" class="spinner-border sr-only"></span>&nbsp;Submit</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
