import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserService} from "../../../service/user.service";
import {User} from "../../../dto/user";
import {AbstractControl, FormControl, FormGroup, ValidatorFn, Validators} from "@angular/forms";
import {Recommendation} from "../../../dto/recommendation";
import {CountryService} from "../../../service/country.service";
import {Country} from "../../../dto/country";
import {SpecializationService} from "../../../service/specialization.service";
import {Specialization} from "../../../dto/specialization";
import {RecommendationService} from "../../../service/recommendation.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {ReplaySubject, takeUntil} from "rxjs";
import {ErrorDTO} from "../../../dto/error-dto";
import {Vacancy} from "../../../dto/vacancy";
import {UserRole} from "../../../dto/user-role";

function equalsIgnoreCase(text1: string, text2: string) {
  return text1.localeCompare(text2, undefined, {sensitivity: 'accent'}) === 0;
}
export function forbiddenStringsValidator() :ValidatorFn ;
export function forbiddenStringsValidator(forbiddenStrings: RegExp): ValidatorFn;
export function forbiddenStringsValidator(forbiddenStrings?: RegExp): ValidatorFn {
  if (forbiddenStrings == undefined) { forbiddenStrings = forbiddenStringsForTextField }
  return (control: AbstractControl): {[key: string]: any} | null => {
    if (control.value) {
      const forbiddenMatch = control.value.match(forbiddenStrings);
      if (forbiddenMatch) {
        return {'forbiddenStrings': true};
      }
    }
    return null;
  };
}

export const forbiddenStringsForTextField = /(https?:\/\/)\S+\.\S+|(www\.)\S+\.\S+|([<>])/i;
const PHONE_FIELD_PATTERN = /^[\d\-()+ ]+$/;
export const blankText = /^(\s+\S+\s*)*(?!\s).*$/;

@Component({
  selector: 'app-send-recommendation',
  templateUrl: './send-recommendation.component.html',
  styleUrls: ['./send-recommendation.component.css']
})
export class SendRecommendationComponent implements OnInit, OnDestroy {

  form: FormGroup;
  user: User = new User();
  countryList: Country[] = [];
  specializationList: Specialization[] = [];
  fileToUpload: File;
  fileContentType: string;
  recommendation: Recommendation;
  base64: string;
  inProgress = false;
  careerVacancy = new Vacancy();
  vacancies: Vacancy[] = [];
  destroy: ReplaySubject<void> = new ReplaySubject<void>(1);

  constructor(private userService: UserService,
              private countryService: CountryService,
              private specializationService: SpecializationService,
              private recommendationService: RecommendationService,
              private router: Router,
              private toastr: ToastrService,
              private activatedRoute: ActivatedRoute) {
    this.careerSiteIncomingData();
    this.initForm();
    const navigation = this.router.getCurrentNavigation();
    if (navigation) {
      const state = navigation.extras.state as { vacancies: Vacancy[] };
      if (state)
        this.vacancies = state.vacancies;
    }
  }

  ngOnInit(): void {
    this.countryService.getCountryList().pipe(takeUntil(this.destroy)).subscribe(response => {
      this.countryList = response;
      this.userService.getExistUser().pipe(takeUntil(this.destroy)).subscribe(response => {
        this.user = response;
        if (this.careerVacancy.vrKey && this.careerVacancy.vrName && this.user.role !== UserRole.ROLE_AGENCY) {
          this.careerVacancy.countryName = this.careerVacancy.countryCode ? this.countryList.find(value => value.code === this.careerVacancy.countryCode).name: null;
        }
        this.initForm();
      });
    });
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  initForm() {
    const recommenderCountry = this.countryList.find(value => value.code === this.user.countryCode);
    this.form = new FormGroup({

      recommenderEmail: new FormControl(this.user.email),
      recommenderPhone: new FormControl(this.user.phone),
      recommenderCountryCode: new FormControl(recommenderCountry ? recommenderCountry.code : ""),
      recommenderFullName: new FormControl(this.user.summary),
      recruiterName: new FormControl('', Validators.email),

      candidateFirstName: new FormControl('', [Validators.required, forbiddenStringsValidator(forbiddenStringsForTextField), Validators.pattern(blankText)]),
      candidateLastName: new FormControl('', [Validators.required, forbiddenStringsValidator(forbiddenStringsForTextField), Validators.pattern(blankText)]),
      candidateEmail: new FormControl('', [Validators.email, Validators.required, Validators.pattern('.*(?<!@dxc+\\.com|@luxoft+\\.com)$')]),
      candidatePhone: new FormControl('', Validators.pattern(PHONE_FIELD_PATTERN)),
      vacancyCode: new FormControl(this.careerVacancy.vrKey ? this.careerVacancy.vrKey : null, forbiddenStringsValidator(forbiddenStringsForTextField)),
      vacancyName: new FormControl(this.careerVacancy.vrName ? this.careerVacancy.vrName : null, forbiddenStringsValidator(forbiddenStringsForTextField)),
      specialization: new FormControl(this.careerVacancy.specializationName,  this.vacancies.length || this.user.role === 'ROLE_AGENCY' ? null : Validators.required),
      cvFile: new FormControl(this.fileToUpload ? this.fileToUpload.name : '', this.user.role === 'ROLE_AGENCY' ? Validators.required : null),
      candidateCountryCode: new FormControl(null, Validators.required),
      agreeTerms: new FormControl('', this.user.role === 'ROLE_AGENCY' ? null : Validators.requiredTrue),
      comment: new FormControl(null, forbiddenStringsValidator(forbiddenStringsForTextField)),
    });
  }


  private careerSiteIncomingData() {
    this.activatedRoute.queryParams.pipe(takeUntil(this.destroy)).subscribe({
      next: (params) => {
        if (Object.keys(params).length !== 0) {
          this.careerVacancy.vrKey = params['vacancy[code]'];
          this.careerVacancy.vrName = params['vacancy[name]'];
          this.careerVacancy.countryCode = params['vacancy[country]'];
          this.careerVacancy.workingPlaceCity = params['vacancy[city]'];
          this.careerVacancy.specializationName = params['vacancy[specialization]'];
          this.vacancies.push(this.careerVacancy);
        } else if (this.vacancies.length) {
          this.vacancies = [];
          this.careerVacancy = new Vacancy();
          this.initForm();
        }
      },
      error: (error) => {
        let errors = <ErrorDTO[]>error.error.errors;
        errors.forEach(e => this.toastr.error(e.errorMessage, ''));
      }
    });

    this.specializationService.getSpecializationList().pipe(takeUntil(this.destroy)).subscribe({
      next: (response) => {
        this.specializationList = response;
        if (this.careerVacancy.specializationName) {
          let specialization = this.specializationList.find(value => equalsIgnoreCase(value.name, this.careerVacancy.specializationName));
          this.careerVacancy.specializationName = specialization ? specialization.name : "IT - Other";
        }
      },
      error: (error) => {
        let errors = <ErrorDTO[]>error.error.errors;
        errors.forEach(e => this.toastr.error(e.errorMessage, ''));
      }
    });
  }

  submit() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
    } else {

      if (this.user.role === UserRole.ROLE_AGENCY && !this.vacancies.length) {
        this.toastr.warning('Cannot save without selected vacancies', '')
        return;
      }

      this.inProgress = true;
      const fullName = this.user.summary.split(" ");
      this.recommendation = {...this.recommendation, ...this.form.value};
      this.recommendation.cvFile = this.base64;
      this.recommendation.cvFileName = this.fileToUpload ? this.fileToUpload.name : '';
      this.recommendation.cvFileContentType = this.fileContentType;
      this.recommendation.recommenderFirstName = fullName[0];
      this.recommendation.recommenderLastName = fullName[1];
      this.recommendation.recommenderUID = this.user.uid;
      this.recommendation.platformType = this.user.platformType;
      this.recommendation.vacancies = this.vacancies;
      this.recommendationService.createRecommendation(this.recommendation)
        .pipe(takeUntil(this.destroy))
        .subscribe({
          next: () => {
            this.form.reset();
            this.inProgress = false;
            this.toastr.success('Recommendation has been created successfully', '');
            this.router.navigate(['/referrer/my-recommendations']);
          },
          error: (error) => {
            let errors = <ErrorDTO[]>error.error.errors;
            errors.forEach(e => this.toastr.error(e.errorMessage, ''));
            this.inProgress = false;
          }
        });
    }
  }

  handleFileInput(files: EventTarget) {
    this.fileToUpload = (files as HTMLInputElement).files[0];
    if (this.fileToUpload.size / 1024 / 1024 > 10) {
      (files as HTMLInputElement).value = null;
      this.form.patchValue({cvFile: ''});
      this.fileContentType = '';
      this.base64 = '';
      this.toastr.error("Error: The file size must not exceed 10 megabytes. Please choose a smaller file and try again.")
      return;
    }

    if (this.fileToUpload) {
      this.form.patchValue({cvFile: this.fileToUpload.name});
      this.fileContentType = this.fileToUpload.type;
      const reader = new FileReader();
      reader.readAsDataURL(this.fileToUpload);
      reader.onload = () => {
        this.base64 = (reader.result as string).split(',').pop();
      };
    } else {
      this.form.patchValue({cvFile: ''});
      this.base64 = '';
      this.fileContentType = '';
    }
  }

  goToInformationPage() {
    this.router.navigate(['/referrer/information']);
  }

  removeVacancy(vacancy: Vacancy) {
    this.vacancies = this.vacancies.filter(v=> v.id !== vacancy.id)
  }
}
