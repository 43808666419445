import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import {environment} from "../environments/environment";

@Injectable({
  providedIn: 'root',
})
export class AppInsightsService {
  private appInsights: ApplicationInsights;

  constructor() {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.instrumentationKey,
        endpointUrl: '/ai-track',
        enableAutoRouteTracking: true,
        loggingLevelConsole: 2,
      },
    });
    this.appInsights.addTelemetryInitializer((envelope) => {
      const tags = envelope.tags || {};
      tags['ai.cloud.role'] = 'RP-UI';
      envelope.tags = tags;
    });
    this.appInsights.loadAppInsights();
  }

  logPageView(name?: string, uri?: string): void {
    this.appInsights.trackPageView({ name, uri });
  }

  logEvent(name: string, properties?: { [key: string]: any }): void {
    this.appInsights.trackEvent({ name }, properties);
  }

  logException(exception: Error, severityLevel?: number): void {
    this.appInsights.trackException({ exception, severityLevel });
  }
}
