import { Component } from '@angular/core';
import {AppInsightsService} from "./app-insights.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor(private appInsightsService: AppInsightsService) {}

  ngOnInit(): void {
    this.appInsightsService.logPageView('HomePage');
  }
}
